footer{
  border-top-left-radius: 86px;
  border-top-right-radius: 86px;
  height: 150px;
}
.foot-nav{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 66px;
  .nav-link{
    font-weight: 600;
    font-size: 18px;
    line-height: 45px;
    color: #000000;
    &:last-child{
      margin-right: 0;
    }
  }
}