*{
  box-sizing: border-box;
}
body{
  font-family: AvenirNext-Regular, sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  background-color: #fff;

  color: #273147;
}

a, a:hover, a:active, a:focus{
  text-decoration: none;
  outline: none;

}

h1,h2,h3,h4,h5,h6{
  margin: 0;
  padding: 0;
}

p{
  margin: 0;
  padding: 0;
}

.clearfix:after {
  content: " "; /* Older browser do not support empty content */
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}

.site-section{
  overflow: hidden;
  width: 100%;
}



//general colors
.bg-gray{
  background-color: #fcfcfc;
}

//general containers
.wrapper, header, footer{
  max-width: 1630px;
  margin: 0 auto;
}

.content-holder, .head-nav, .foot-nav{
  max-width: 1256px;
  margin: auto;
  padding: 0 20px;
}

.app-links{
  display: flex;
  flex-wrap: wrap;
  &__title{
    width: 100%;
  }
}

//titles

.section-header{
  font-family: AvenirNext-DemiBold, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 28px;

  text-align: center;

  color: #202020;

  &--intro{
    font-family: AvenirNext-Bold, sans-serif;
    font-weight: bold;
    font-size: 69px;
    line-height: 117px;
    text-align: left;
    margin-bottom: 5px;
  }
  &--set{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #202020;
  }
}

//buttons
.text-link{
  color: #05bacb;
  text-transform: uppercase;
}
.app-link{
  display: flex;
  align-items: center;
  justify-content: left;
  width: 213px;
  height: 83px;
  background-color: #fff;
  border-radius: 40px;
  box-shadow: 0px 2px 71px rgba(0, 0, 0, 0.0506397);
  padding: 24px;
  cursor: pointer;
  transition: background-color .4s ease;
  &__img{
    width: 47px;
    height: 47px;
    border: 1px solid #a9a9a9;
    border-radius: 40px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border-color .4s ease;
    img{
      display: block;
      max-width: 24px;
      height: auto;
    }
  }
  &__text{
    color: #000;
    font-family: AvenirNext-Medium, sans-serif;
    margin-left: 8px;
    transition: color .4s ease;
    &--top{
      font-size: 11px;
      display: block;
    }
    &--bot{
      display: block;
      font-size: 15px;
    }
  }
  &:hover{
    background-color: #05bacb;
    .app-link__img{
      border-color: #fff;
    }
    .app-link__text{
      color: #fff;
    }
  }
}

.logo{
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.0473845);
  display: flex;
  align-items: center;
  justify-content: center;

  &--header{
    height: 86px;
    width: 86px;
    margin: 25px 0 0 44px;
  }
  &--footer{
    width: 68px;
    height: 63px;
    padding: 18px;
    img{
      width: 100%;
      height: auto;
    }
  }
}

.nav-link{
  font-family: AvenirNext-DemiBold, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 45px;
  color: #000000;
  margin: 0 28px;
  display: inline-block;
  position: relative;

  &:before{
    content: '';
    display: block;
    height: 3px;
    width: 0;
    background-color: #05bacb;
    position: absolute;
      right: 50%;
      bottom: 0;
    transition: .4s .1s linear;
  }
  &:after{
    content: '';
    display: block;
    height: 3px;
    width: 0;
    background-color: #05bacb;
    position: absolute;
      left: 50%;
      bottom: 0;
    transition: .4s .1s linear;
  }
}
.nav-link:hover:before, .nav-link:hover:after{
  width: 21px;
}

.submit-btn{
  background: linear-gradient(13.6deg, #0CAFD6 0%, #00CDBF 100%);
  border-radius: 30px;
  color: #fff;
  font-family: Avenir-Medium, sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  box-shadow: none;
  border: none;
  outline: none;
  display: block;
  width: 248px;
  height: 60px;
  flex-shrink: 0;
  cursor: pointer;
  &:hover{
    opacity: 0.7;
  }
}

//fonts
@font-face {
  font-family: "AvenirNext-Regular";
  src: url("../fonts/AvenirNext-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "AvenirNext-Bold";
  src: url("../fonts/AvenirNext-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: "AvenirNext-Medium";
  src: url("../fonts/AvenirNext-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "AvenirNext-DemiBold";
  src: url("../fonts/AvenirNext-DemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

//intro
.intro{
  .wrapper{
    border-bottom-left-radius: 86px;
    border-bottom-right-radius: 86px;
  }
  .content-holder{
    position: relative;
    padding-bottom: 167px;
  }
  .app-links{
    margin-top: 17px;
    &__title{
      font-family: Avenir-Medium, sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 47px;
      color: #000000;
      mix-blend-mode: normal;
      opacity: 0.64;
      margin: 20px 0 0 6px;
    }
  }
  .app-link{
    margin-right: 29px;
  }
}
.intro__text{
  font-family: AvenirNext-Medium, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 17px;
  flex-shrink: 0;
  color: #000000;
  padding-top: 46px;
  margin-left: 41px;
  position: relative;
  z-index: 1;
}
.intro__text-item{
  margin: 0 0 18px 6px;
}

.intro__pic{
  position: absolute;
  top: -97px;
  left: 41%;
}
.intro__list{
  list-style-type: none;
  padding: 0;
  margin: 0;
  .intro__text-item{
    margin: 0;
  }
}
.intro__list-item{
  display: flex;
  align-items: center;
  margin: 2px 0 0 0;
  font-size: 18px;
  line-height: 38px;

  color: #000000;

  mix-blend-mode: normal;
  opacity: 0.64;
  img{
    width: 18px;
    height: 18px;
    margin: 0 7px 0 6px;
  }

  &--cap{
    opacity: 1;
    font-size: 20px;
    margin-top: 23px;
    margin-left: 6px;
  }
  &--cap:before{
    display: none;
  }
}

//can-do

.can-do{
  margin-top: -50px;
  &__content{
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }
  &__item{
    width: 390px;
    height: 390px;
    background-color: #fff;
    box-shadow: 0px 2px 71px rgba(0, 0, 0, 0.0506397);
    border-radius: 20px;
    padding: 30px 0 49px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

  }
  &__img{
    display: block;
  }
  &__item-text{
    font-family: AvenirNext-DemiBold, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #202020;
  }
}

//get started

.get-started{
  margin-top: 145px;
  .wrapper{
    border-radius: 86px;

  }
  &__content{
    margin-top: 18px;
    padding: 32px 20px 74px 20px;
  }
  &__row{
    display: flex;
    justify-content: center;
    margin-top: 95px;

    &--double{
      margin-top: 35px;
    }

    &:first-child{
      margin-top: 0;
    }
    &:last-child{
      margin-top: 48px;
    }
  }

}
.step{
  text-align: center;
  font-family: AvenirNext-DemiBold, sans-serif;
  position: relative;
  &--double{
    margin: 0 65px;
  }

  &:after{
    display: block;
    content: url("../img/line.svg");
    position: absolute;
  }
  &--1:after{
    bottom: -80px;
    left: calc(50% - 3px);
  }
  &--2:after{
    content: url("../img/line2.svg");
    top: 25px;
    right: -120px;
    transform: scale(-1, 1)
  }
  &--2:before{
    display: block;
    position: absolute;
    content: url("../img/line2.svg");
    top: 25px;
    left: -112px;
  }
  &--3:after{
    display: none;
  }
  &--4:after{
    content: url("../img/line3.svg");
    top: -25px;
    right: -100px;
  }
  &--4:before{
    display: block;
    position: absolute;
    content: url("../img/line2.svg");
    top: -23px;
    left: -108px;
    transform: rotate(-90deg);
  }

  &__option{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #202020;
    margin-bottom: 30px;
  }

  &__number{
     margin: auto;
     border-radius: 30px;
     padding: 5px;

     width: 36px;
     height: 36px;
     color: #fff;
     font-weight: 600;
     font-size: 20px;
     line-height: 27px;
     text-align: center;
     /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#11b1d6+0,05cec1+100 */
     background: #11b1d6; /* Old browsers */
     background: -moz-linear-gradient(top,  #11b1d6 0%, #05cec1 100%); /* FF3.6-15 */
     background: -webkit-linear-gradient(top,  #11b1d6 0%,#05cec1 100%); /* Chrome10-25,Safari5.1-6 */
     background: linear-gradient(to bottom,  #11b1d6 0%,#05cec1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
     filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#11b1d6', endColorstr='#05cec1',GradientType=0 ); /* IE6-9 */
  }
  &__text{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #202020;
    mix-blend-mode: normal;
    opacity: 0.64;
    margin-top: 15px;
  }
}

//set

.set{
  .content-holder{
    margin: 62px auto;
  }
  .app-links{
    margin: 28px auto 0 auto;
    width: 450px;
    justify-content: space-between;
  }
}

//why

.why{
  .content-holder{
    position: relative;
    padding-bottom: 120px;
  }
  .section-header{
    text-align: left;
  }
  &__text{
    width: 50%;
    float: right;
    padding: 167px 0 0 13px;
    font-family: AvenirNext-DemiBold, sans-serif;
  }
  &__paragraph{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #202020;
    mix-blend-mode: normal;
    opacity: 0.64;
    margin-top: 45px;
  }
  &__pic{
    position: absolute;
      top: 59px;
      right: 55.4%;
  }

  .app-links{
    margin-top: 60px;
  }
  .app-link:first-child{
    margin-right: 25px;
  }
}

//team

.team{
  font-family: AvenirNext-DemiBold, sans-serif;
  .content-holder{
    padding-bottom: 110px;
  }
  &__list{
    display: flex;
    justify-content: center;
    margin-top: 60px;
  }
  &__card{
    width: 290px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;

    .name{
      color: #202020;
    }
    .position{
      font-family: Avenir-Medium, sans-serif;
      font-weight: 500;
      color: #919191;
    }
  }
  .avatar{
    width: 164px;
    height: 164px;
    border: 8px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 150px;
    box-shadow: 0px 2px 71px rgba(0, 0, 0, 0.0506397);
    img{
      width: 100%;
      height: auto;
    }
  }
  .mark{
    position: relative;
    margin-bottom: 25px;
    &:before{
      display: block;
      position: absolute;
        bottom: 15px;
        right: 15px;
      content: '';
      background-color: #05bacb;
      border: 3px solid #fff;
      width: 19px;
      height: 19px;
      border-radius: 20px;
    }
  }
}

//contacts
.contact{
  .content-holder{
    padding-bottom: 100px;
  }
}
.contact__content{
  margin-top: 60px;
}
.fields, .textarea{
  display: flex;
  flex-direction: column;
  width: 50%;
  font-family: Avenir-Medium, sans-serif;
}
.fields{
  &__label{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
    mix-blend-mode: normal;
    opacity: 0.64;
    margin-bottom: 20px;
    margin-left: 22px;
  }
}
.input-field{
  background: #FFFFFF;
  box-shadow: 0 1px 30px rgba(0, 0, 0, 0.05);
  -webkit-appearance: none;
  border-radius: 25px;
  max-width: 484px;
  height: 50px;
  width: 100%;
  border: none;
  padding: 5px 22px;
  font-size: 20px;
}
.textarea{
  align-items: flex-end;
  padding-left: 20px;
  label{
    width: 100%;
  }
  textarea{
    width: 100%;
    height: 159px;
    border: none;
    background: #FFFFFF;
    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.05);
    -webkit-appearance: none;
    border-radius: 4px;
    padding: 20px 22px;
  }
  .submit-btn{
    margin-top: 25px;
    text-transform: capitalize;
  }
}
.form-content{
  display: flex;
  padding: 0 44px;
  label{
    display: flex;
    flex-direction: column;
    margin-bottom: 13px;
  }
}