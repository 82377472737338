@media all and (max-width: 1256px){
  .can-do__item{
    width: 375px;
    height: 375px;
    padding: 30px 15px;
  }
}

@media all and (max-width: 1200px){

  .head-nav{
    height: 90px;
    padding: 0 40px;
    max-width: 900px;
  }
  .logo--header {
    height: 66px;
    width: 66px;
    margin: 20px 0 0 0;
    padding: 15px;
    img{
      width: 100%;
      height: auto;
    }
  }
  .nav-link:last-child{
    margin-right: 0;
  }

  .content-holder{
    padding-left: 40px;
    padding-right: 40px;
    max-width: 900px;
  }

  .section-header{
    font-size: 26px;
  }
  .section-header--intro{
    font-size: 50px;
    line-height: 85px;
  }

  .app-link{
    width: 190px;
    height: auto;
    padding: 10px 16px;
    &__img{
      width: 47px;
      height: 47px;
    }
    &__text{
      &--top{
        font-size: 10px;
      }
      &--bot{
        font-size: 13px;
      }
    }
  }
  .submit-btn{
    width: 190px;
    height: 50px;
  }

  .intro .content-holder{
    padding-bottom: 120px;
  }
  .intro .app-link{
    margin-right: 0;
    &:first-child{
      margin-right: 15px;
    }
  }
  .intro .app-links__title{
    font-size: 14px;
    margin: 20px 0 0 0;
  }
  .intro__text{
    font-size: 18px;
    line-height: 22px;
    margin-left: 0;
    max-width: 440px;
    padding: 20px 0 0 0;
  }
  .intro__text-item{
    margin: 0 0 18px 0;
    max-width: 390px;
  }
  .intro__list-item--cap{
    margin-left: 0;
  }
  .intro__list-item--cap .intro__text-item{
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 15px;
  }
  .intro__list-item{
    font-size: 16px;
  }
  .intro__list-item img{
    margin-left: 0;
  }
  .intro__pic{
    top: -30px;
    left: calc(50% - 165px);
    right: auto;
    width: 780px;
    img{
      width: 100%;
      height: auto;
    }
  }


  .can-do__item{
    width: 260px;
    height: 260px;
    padding: 20px 10px;
    border-radius: 10px;
  }
  .can-do__item-text{
    font-size: 14px;
    line-height: 20px;
    min-height: 80px;
    br{
      display: none;
    }
  }
  .can-do__img {
    display: block;
    max-width: 150px;
    max-height: 120px;
  }


  .get-started{
    margin-top: 70px;
  }
  .get-started__content{
    padding: 32px 40px;
    max-width: 900px;
    margin: auto;
  }
  .step__text{
    font-size: 16px;
    line-height: 24px;
  }
  .step__option {
    font-size: 18px;
  }

  .why__text{
    padding-top: 70px;
  }
  .why__paragraph{
    font-size: 16px;
    margin-top: 25px;
  }
  .why__pic{
    top: 90px;
    right: 53%;
    width: 100%;
    img{
      width: 100%;
      height: auto;
    }
  }
  .why .app-link:first-child{
    margin-right: 15px;
  }

  .team__list{
    justify-content: space-between;
    margin-top: 40px;
  }
  .team__card{
    width: 200px;
    font-size: 16px;
  }
  .team .avatar{
    width: 132px;
    height: 132px;
  }
  .team .mark:before {
    bottom: 11px;
    right: 11px;
    width: 16px;
    height: 16px;
  }
  .team .content-holder{
    padding-bottom: 70px;
  }


  .contact .content-holder{
    padding-bottom: 70px;
  }
  .form-content{
    padding: 0;
  }
  .fields__label{
    font-size: 18px;
    margin-bottom: 18px;
  }
  .textarea .submit-btn{
    margin-top: 15px;
  }
  .textarea textarea{
    height: 186px;
  }
  .c-popup .content-holder{
    padding: 10px 40px;
  }
  .c-popup-text-block{
    font-size: 14px;
  }
}

@media all and (max-width: 900px){
  .logo{
    border-radius: 12px;
  }
  .logo--header{
    width: 50px;
    height: 50px;
    padding: 10px;
  }
  .head-nav{
    padding: 0 20px;
    max-width: 465px;
    height: 60px;
  }
  .nav-link{
    font-size: 16px;
    margin: 0 10px;
  }

  .section-header--intro{
    font-size: 48px;
  }

  .content-holder{
    padding-right: 20px;
    padding-left: 20px;
    max-width: 465px;
  }


  .intro .content-holder {
    padding-bottom: 470px;
  }
  .intro__text {
    font-size: 16px;
    line-height: 22px;
    margin: auto;
    max-width: 425px;
    padding: 20px 0 0 0;
  }
  .intro__text-item{
    max-width: none;
  }
  .intro__list-item--cap .intro__text-item{
    font-size: 16px;
  }
  .intro .app-links{
    justify-content: space-between;
  }
  .intro .app-links__title{
    text-align: center;
  }
  .intro__pic {
    top: 440px;
    left: -95px;
    right: auto;
    width: 620px;
  }


  .can-do__content{
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .can-do__item {
    width: 100%;
    height: 400px;
    padding: 20px 20px;
    border-radius: 10px;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  .can-do__img{
    max-width: none;
    max-height: none;
  }
  .can-do__item-text{
    min-height: auto;
  }

  .get-started__content{
    padding-left: 20px;
    padding-right: 20px;
    max-width: 465px;
  }
  .step{
    max-width: 190px;
  }
  .step--double{
    margin: 0;
  }
  .step__text{
    font-size: 13px;
    br{
      display: none;
    }
  }
  .step__option{
    font-size: 14px;
    margin-bottom: 15px;
  }
  .get-started__row{
    margin-top: 80px;
  }
  .get-started__row--double{
    justify-content: space-between;
    margin-top: 20px;
  }
  .get-started__row:last-child {
    margin-top: 85px;
  }
  .step--2:before{
    top: 17px;
    left: -30px;
  }
  .step--2:after {
    top: 17px;
    right: -30px;
  }
  .step--4:after{
    top: -75px;
    right: -30px;

  }
  .step--4:before{
    top: -75px;
    left: -30px;
    content: url("../img/line3.svg");
    transform: scale(-1,1);
  }

  .set .app-links{
    max-width: none;
    width: 100%;
  }

  .why .content-holder{
    padding-bottom: 70px;
  }
  .why .section-header{
    text-align: center;
  }
  .why__text{
    position: relative;
    max-width: none;
    width: 100%;
    z-index: 1;
    padding: 0;
  }
  .why__pic{
    display: none;
  }

  .team__list{
    flex-wrap: wrap;
  }
  .team__card{
    margin-bottom: 20px;
  }

  .form-content{
    flex-direction: column;
  }
  .fields{
    width: 100%;
  }
  .textarea{
    width: 100%;
    padding: 0;
  }
  .textarea .submit-btn{
    margin: 15px auto 0 auto;
  }


  .foot-nav{
    padding: 30px 20px;
    max-width: 465px;
  }
  .logo--footer {
    width: 50px;
    height: 50px;
    padding: 10px;
  }
  .foot-nav .nav-link{
    font-size: 14px;
    margin: 0 5px;
  }
  .epopup-inner{
    padding: 50px 50px 40px 50px;
    max-width: 465px;
  }
  .popup-header{
    font-size: 16px;
  }
  .c-popup .content-holder{
    flex-direction: column;
  }
  .c-popup-text-block{
    padding: 0;
    font-size: 12px;
    line-height: 20px;
  }

  .c-popup .submit-btn{
    width: 140px;
    height: 36px;
    font-size: 12px;
    margin-top: 10px;
  }
}

@media all and (max-width: 600px){
  .submit-btn{
    width: 140px;
    height: 36px;
    font-size: 12px;
    margin-top: 10px;
  }

  .head-nav{
    padding: 0 15px;
  }
  .nav-link {
    font-size: 14px;
    margin: 0px 5px;
  }
  .section-header--intro{
    font-size: 32px;
  }
  .content-holder{
    max-width: 320px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .intro .wrapper {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  .intro__list-item{
    font-size: 14px;
  }
  .intro .app-links{
    justify-content: center;
    align-items: center;
  }
  .intro .app-link:first-child{
    margin-right: 0;
  }
  .intro .app-link{
    margin-bottom: 20px;
  }
  .intro__pic {
    top: auto;
    left: auto;
    right: auto;
    width: 100%;
    position: relative;
  }
  .intro .app-links__title {
    line-height: 20px;
    margin-top: 15px;
  }
  .intro .content-holder {
    padding-bottom: 20px;
  }
  .can-do__item{
    height: 290px;
  }
  .can-do__img {
    max-width: 150px;
    max-height: 120px;
  }

  .get-started {
    margin-top: 50px;
  }
  .get-started .wrapper{
    border-radius: 30px;
  }
  .get-started__content {
    padding-left: 15px;
    padding-right: 15px;
    max-width: none;
  }
  .step__text {
    font-size: 12px;
  }
  .step--2:before{
    content: url("../img/line.svg");
    top: 117px;
    left: 20px;
  }
  .step--2:after{
    content: url("../img/line.svg");
    top: 117px;
    right: 20px;
  }
  .step--4:before{
    content: url("../img/line.svg");
    top: -65px;
    left: 50px;
    transform: rotate(-33deg);
  }
  .step--4:after{
    content: url("../img/line.svg");
    top: -65px;
    right: 50px;
    transform: rotate(33deg);
  }
  .get-started__row--double {
    margin-top: 100px;
  }


  .set .content-holder {
    margin: 30px auto;
  }

  .set .app-link, .why .app-link{
    margin: 0 0 20px 0;
  }
  .set .app-links, .why .app-links{
    align-items: center;
    justify-content: center;
  }

  .why .app-link:first-child{
    margin: 0 0 20px 0;
  }

  .team__list{
    align-items: center;
    justify-content: center;
  }

  .input-field{
    font-size: 14px;
    height: 40px;
  }
  .fields__label{
    font-size: 14px;
  }
  .textarea{
    padding: 0;
  }

  footer{
    border-radius: 30px;
    height: auto;
  }
  .foot-nav{
    align-items: flex-start;
    padding: 15px;
    justify-content: space-evenly;
    &__links{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .epopup-inner {
    padding: 50px 15px 30px 15px;
    max-width: none;
  }
  .epopup-inner .submit-btn {
    margin: 30px auto 0 auto;
  }
  .popup-header {
    font-size: 14px;
  }
}