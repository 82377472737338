
.popup {
  font-family: AvenirNext-DemiBold, sans-serif;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  z-index: 10;
  overflow: auto;
  background: rgba(169, 169, 169, 0.56);;
}
.popup-header{
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #202020;
}
.epopup-inner {
  max-width: 576px;
  width: 90%;
  padding: 72px 72px 45px 72px;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  box-shadow: 0px 2px 71px rgba(0, 0, 0, 0.0506397);
  border-radius: 20px;
  .fields{
    margin-top: 15px;
    width: 100%;
    &__label{
      display: inline-block;
      margin: 0 0 18px 20px;
    }
  }
  .submit-btn{
    margin: 60px auto 0 auto;
  }
}
.inner-close{
  width: 25px;
  height: 25px;
  border-radius: 30px;
  border: 1px solid #05bacb;
  position: absolute;
    top: 15px;
    right: 15px;
  .cross{
    height: 15px;
    width: 1px;
    background-color: #05bacb;
    position: absolute;
    top: 4px;
    left: 11px;
    &:first-child{
      transform: rotate(45deg);
    }
    &:last-child{
      transform: rotate(-45deg);
    }
  }
}

//cookies
.c-popup{
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9;
  box-shadow: 0px 2px 71px rgba(0, 0, 0, 0.0506397);
  .content-holder{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 64px;
  }
}

.c-popup-text-block{
  font-family: AvenirNext-Regular, sans-serif;
  max-width: 860px;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #202020;
  padding-right: 20px;
  box-sizing: content-box;
}
.c-popup-caption{
  font-family: AvenirNext-Medium, sans-serif;
  color: #202020;
}